//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import speciesSearch from '~/graphql/queries/speciesSearch.gql';

export default {
  props: {
    clearOnAdd: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      species: [],
      searchValue: null,
    };
  },
  watch: {
    async searchValue() {
      try {
        const {data} = await this.$apollo.query({
          query: speciesSearch,
          variables: {
            query: this.searchValue,
          },
        });

        if (data.speciesSearch) {
          this.species = data.speciesSearch;
        }
      } catch (e) {
        console.error(e);
      }
    },
  },
  methods: {
    getColorText(title) {
      return title.replace(this.searchValue, '<span class="text-primary-light">' + this.searchValue + '</span>');
    },
    add(item) {
      this.$emit('add', item);
      if (this.clearOnAdd) {
        this.searchValue = '';
      }
    },
  },
};
