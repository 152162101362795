import {page as pageQuery} from '~/graphql/queries/page.gql';
import {menus as menusQuery} from '~/graphql/queries/menus/menus.gql';

export const state = () => ({
  release: process.env.RELEASE,
  settings: {},
  page: {seo: {}, template: {}},
  menuMain: {},
  menuFooter: {},
});

const getMenuItems = (menus, name) => {
  const menu = menus.find(m => m.name === name);

  if (!menu) {
    return [];
  }

  return menu.items;
};

export const actions = {
  async nuxtServerInit({dispatch}) {
    try {
      await dispatch('storeDispatchFunc');
    } catch (e) {
      console.error(e);
    }
  },

  async storeDispatchFunc({commit}) {
    const {data: {menus: menuData}} = await this.app.apolloProvider.defaultClient.query({
      query: menusQuery,
      variables: {
        names: ['main', 'footer'],
      },
    });

    commit('SET_MENU_MAIN', getMenuItems(menuData, 'main'));
    commit('SET_MENU_FOOTER', getMenuItems(menuData, 'footer'));
  },

  async getPage({commit}, slug = 'home') {
    const {
      data: {page},
    } = await this.app.apolloProvider.defaultClient.query({
      query: pageQuery,
      fetchPolicy: 'no-cache',
      variables: {
        segments: slug === '' ? 'home' : slug,
      },
    });

    if (!page) {
      const e = new Error();
      e.statusCode = 410;
      throw e;
    }

    commit('SET_PAGE', page);
  },
  nuxtClientInit({state, commit}, context) {
    if (state.release) {
      context.store.dispatch('version/checkForNewVersion');
    }
  },
};

export const mutations = {
  SET_SETTINGS(state, payload) {
    state.settings = payload;
  },
  SET_PAGE(state, payload) {
    state.page = payload;
  },
  SET_MENU_MAIN(state, payload) {
    state.menuMain = payload;
  },
  SET_MENU_FOOTER(state, payload) {
    state.menuFooter = payload;
  },
};
