//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    date: null,
    modelConfig: {
      mask: 'DD-MM-YYYY',
    },
    attrs: [
      {
        key: 'today',
        highlight: {
          class: 'today',
        },
        dates: new Date(),
      },
    ],
  }),
  computed: {
    pickerAttributes() {
      return Object.assign(this.context.attributes, {id: null, class: null});
    },
    isDisabled() {
      return 'disabled' in this.context.attributes;
    },
  },
  watch: {
    date(newDate) {
      this.context.model = this.$moment(newDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
    },
    context: {
      deep: true,
      handler() {
        if (this.context.model) {
          this.date = this.$moment(this.context.model, 'YYYY-MM-DD').format('DD-MM-YYYY');
        }
      },
    },
  },
  mounted() {
    if (this.context.model) {
      this.date = this.$moment(this.context.model, 'YYYY-MM-DD').format('DD-MM-YYYY');
    }
    if (this.date) {
      this.context.model = this.$moment(this.date, 'DD-MM-YYYY').format('YYYY-MM-DD');
    }
  },
  methods: {
    parseDate(value) {
      return this.$moment(value, 'DD-MM-YYYY', true).isValid();
    },
  },
};
