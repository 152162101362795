import Vue from 'vue';
import {library, config} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';

import {
  faTrash,
  faStar,
  faBell,
  faThLarge,
  faRss,
  faUsers,
  faQuestion,
  faSignOutAlt,
  faExclamation,
  faPaperPlane,
  faEye,
  faCog,
  faAngleRight,
  faSortUp,
  faSortDown,
  faArrowLeft,
  faFileUpload,
  faSort,
  faAngleLeft,
  faSearch,
  faTimes,
  faArchive,
  faListUl,
  faUndoAlt,
  faRedoAlt,
  faLink,
  faUnlink,
  faBold,
  faItalic,
  faPlus,
  faMinus,
  faBolt,
  faUnderline,
  faCloud,
  faCloudRain,
  faCloudSun,
  faSun,
  faThermometerHalf,
  faThermometerEmpty,
  faThermometerFull,
  faClock,
  faCalendar,
  faTrashCan,
  faEdit,
  faRightToBracket,
  faCircleQuestion,
  faCheck,
  faCaretDown,
  faCaretUp,
  faArrowRight,
  faClose,
  faLocationPin,
  faBriefcase,
  faEnvelope,
  faPhone,
  faMobile,
  faBars,
  faAngleDown,
} from '@fortawesome/free-solid-svg-icons';

import {
  faSpinnerThird,
  faCactus,
} from '@fortawesome/pro-regular-svg-icons';

library.add(
  faTrash,
  faStar,
  faBell,
  faThLarge,
  faRss,
  faUsers,
  faQuestion,
  faSignOutAlt,
  faExclamation,
  faPaperPlane,
  faEye,
  faCog,
  faAngleRight,
  faSortUp,
  faSortDown,
  faArrowLeft,
  faFileUpload,
  faSort,
  faAngleLeft,
  faSearch,
  faTimes,
  faArchive,
  faListUl,
  faUndoAlt,
  faRedoAlt,
  faLink,
  faUnlink,
  faBold,
  faItalic,
  faPlus,
  faMinus,
  faBolt,
  faSpinnerThird,
  faUnderline,
  faCloud,
  faThermometerHalf,
  faThermometerEmpty,
  faThermometerFull,
  faClock,
  faCalendar,
  faCloudRain,
  faCloudSun,
  faSun,
  faCactus,
  faTrashCan,
  faEdit,
  faRightToBracket,
  faCircleQuestion,
  faCheck,
  faCaretDown,
  faCaretUp,
  faArrowRight,
  faClose,
  faLocationPin,
  faBriefcase,
  faEnvelope,
  faPhone,
  faMobile,
  faBars,
  faAngleDown,
);

config.autoAddCss = false;

Vue.component('FontAwesomeIcon', FontAwesomeIcon);
