//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data: () => ({
    mobileOpen: false,
  }),
  computed: {
    mainMenu() {
      return [
        {
          name: 'Dashboard',
          path: this.localePath({name: '/'}),
        },
        {
          name: 'Opdrachtgevers',
          path: this.localePath({name: 'opdrachtgevers'}),
        },
        {
          name: 'Projecten',
          path: this.localePath({name: 'projecten'}),
        },
        {
          name: 'Rapportages',
          path: this.localePath({name: 'rapportages'}),
        },
        {
          name: 'Gebruikers',
          path: this.localePath({name: 'gebruikers'}),
        },
      ];
    },
    displayBlockOne() {
      return !!(this.$route.path === '/' || this.$route.path.includes('nieuw') || this.$route.params.uuid);
    },
  },
  methods: {
    isActive(page) {
      if (page !== '/') {
        return (this.$route.path).includes(page);
      }
      return this.$route.path === '/';
    },
  },
};
