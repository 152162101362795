//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Loader from '~/components/base/Loader';

export default {
  components: {
    Loader,
  },
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  methods: {
    checkAttribute(attribute) {
      const foundAttribute = this.context.attributes[attribute];
      return foundAttribute && (foundAttribute === '' || foundAttribute === true);
    },
  },
};
