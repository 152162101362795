//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    bool: false,
  }),
};
