import {onError} from 'apollo-link-error';

export default function ({app}) {
  // Try to include the cookie package and throw an error if it isn't installed
  try {
    require('cookie-universal-nuxt');
  } catch (e) {
    e.message = 'Missing cookie-universal-nuxt.';

    throw e;
  }

  // Handle errors thrown in GraphQL response
  const errorLink = onError(({graphQLErrors}) => {
    if (graphQLErrors) {
      graphQLErrors.map((error) => {
        if (error.message === 'validation') {
          app.store.dispatch('validation/setErrors', error.extensions.validation);
        }
      });
    }
  });

  // GraphQL link handler
  const linkOptions = {
    uri: process.env.API_URL.replace('/api', '') + '/graphql',
    fetch: (uri, options) => {
      app.store.dispatch('validation/clearErrors');

      // Add authorization token
      const token = app.$cookies.get('auth._token.apollo');

      if (token) {
        options.headers.authorization = token;
      }

      return fetch(uri, options);
    },
  };

  return {
    link: errorLink,
    httpLinkOptions: linkOptions,
    batching: true,
  };
}
