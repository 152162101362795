//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import clientsSearch from '~/graphql/queries/clientsSearch.gql';

export default {
  apollo: {
    clients: {
      query: clientsSearch,
      variables() {
        return {
          query: this.searchValue,
        };
      },
      update({clientsSearch}) {
        this.selectedIndex = 0;

        return clientsSearch;
      },
    },
  },
  props: {
    context: {
      type: Object,
      required: true,
    },
    clearOnSelect: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      searchValue: '',
      clients: [],
      selectedIndex: 0,
      opened: false,
    };
  },
  computed: {
    selection() {
      if (this.clients[this.selectedIndex]) {
        return this.clients[this.selectedIndex];
      }
      return false;
    },
  },
  watch: {
    context() {
      if (this.context && this.context.model) {
        this.searchValue = this.context.model.title;
      }
    },
  },
  methods: {
    getColorText(title) {
      return title.replace(this.searchValue, '<span class="text-primary-light">' + this.searchValue + '</span>');
    },
    select(item) {
      this.context.model = item;
      this.searchValue = item.title;
      this.opened = false;
    },
    changeIndex(increment = true) {
      const length = this.clients.length;
      if (increment ? this.selectedIndex + 1 < length : this.selectedIndex - 1 >= 0) {
        if (increment) {
          this.selectedIndex++;
        } else {
          this.selectedIndex--;
        }
      } else {
        this.selectedIndex = increment ? 0 : length - 1;
      }
    },
    increment() {
      this.changeIndex();
    },
    decrement() {
      this.changeIndex(false);
    },
  },
};
