//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    context: {
      type: Object,
      required: true,
    },
    location: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    species: null,
  }),
  methods: {
    addSpecies(item) {
      if (!this.context.model.filter(s => s.uuid === item.uuid).length) {
        this.context.model.push(item);
      } else {
        this.$toast.error('Deze soort bestaat al in deze zaailijst');
      }
      // Refocus the input after adding a species
      this.$refs.speciesSearch.$children[1].$refs.input.$children[0].$el.children[1].focus();
    },
    deleteSpecies(uuid) {
      this.context.model.forEach((item, i) => {
        if (item.uuid === uuid) {
          this.context.model.splice(i, 1);
        }
      });
    },
  },
};
