//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  computed: {
    options() {
      return {
        min: this.context.attributes.min || 0,
        max: this.context.attributes.max || 100,
      };
    },
  },
};
