//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {Editor, EditorContent} from '@tiptap/vue-2';
import StarterKit from '@tiptap/starter-kit';
import Link from '@tiptap/extension-link';
import Placeholder from '@tiptap/extension-placeholder';
import Mention from '@tiptap/extension-mention';

export default {
  components: {
    EditorContent,
  },
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      editor: null,
      positionInEditor: 1,
    };
  },
  watch: {
    'context.model'(value) {
      if (this.editor && this.editor.getHTML() !== value) {
        this.editor.commands.setContent(value, true);
      }
    },
  },
  mounted() {
    this.editor = new Editor({
      content: this.context.model,
      extensions: [
        StarterKit,
        Placeholder.configure({
          placeholder: 'Schrijf hier je bericht...',
        }),
        Link.configure({
          openOnClick: false,
        }),
        Mention.configure({
          HTMLAttributes: {
            class: 'variable',
          },
          renderLabel({node}) {
            return `${node.attrs.label ?? node.attrs.id}`;
          },
          suggestion: {
            char: '//',
            render: () => {
              return {
                onExit: (props) => {
                  if (props.query !== '') {
                    props.command({id: props.query});
                  }
                },
              };
            },
          },
        }),
      ],
      onUpdate: ({editor}) => {
        this.context.model = editor.getHTML();
      },
      onTransaction: ({transaction}) => {
        this.positionInEditor = transaction.curSelection.$head.pos;
      },
    });
  },
  beforeDestroy() {
    if (this.editor) {
      this.editor.destroy();
    }
  },
  methods: {
    setLink() {
      const previousUrl = this.editor.getAttributes('link').href;
      const url = window.prompt('Type hier je link:', previousUrl);

      if (url === null) {
        return;
      }

      if (url === '') {
        this.editor
          .chain()
          .focus()
          .extendMarkRange('link')
          .unsetLink()
          .run();

        return;
      }

      this.editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .setLink({href: url})
        .run();
    },
  },
};
