export const actions = {
  checkForNewVersion({rootState}) {
    const interval = setInterval(async () => {
      let data;

      try {
        const response = await this.$axios.get('/release-name');

        data = response.data;
      } catch (e) {
        console.error(e);
      }

      const serverRelease = data.trim();

      if (serverRelease && rootState.release !== serverRelease) {
        this.$toast.info(
          'Er is een nieuwe versie beschikbaar. Vernieuw de pagina om deze te activeren.',
          {
            action: [
              {
                text: 'Vernieuwen',
                onClick: () => {
                  window.location.reload();
                },
              },
              {
                text: 'Sluiten',
                onClick: () => {
                  this.$toast.clear();
                },
              },
            ],
          },
        );

        clearInterval(interval);
      }
    }, 60000 * 10); // Every 10 min;
  },
};
