import Vue from 'vue';
import VueFormulate from '@braid/vue-formulate';
import {nl} from '@braid/vue-formulate-i18n';
import errorHandler from '~/libs/form-error-handler';

import Button from '~/components/formulate/Button';
import Date from '~/components/formulate/Date';
import Datepicker from '~/components/formulate/Calendar';
import Toggle from '~/components/formulate/Toggle';
import Species from '~/components/formulate/Species';
import LocationList from '~/components/formulate/LocationList';
import Client from '~/components/formulate/Client';
import Tiptap from '~/components/formulate/Tiptap';
import Percentage from '~/components/formulate/Percentage';
import RangeSlider from '~/components/formulate/RangeSlider';
import Search from '~/components/formulate/Search';

Vue.component('Button', Button);
Vue.component('Date', Date);
Vue.component('Datepicker', Datepicker);
Vue.component('Toggle', Toggle);
Vue.component('Species', Species);
Vue.component('LocationList', LocationList);
Vue.component('Client', Client);
Vue.component('Tiptap', Tiptap);
Vue.component('Percentage', Percentage);
Vue.component('RangeSlider', RangeSlider);
Vue.component('Search', Search);

Vue.use(VueFormulate, {
  library: {
    button: {
      classification: 'button',
      component: Button,
    },
    submit: {
      classification: 'button',
      component: Button,
    },
    date: {
      classification: 'date',
      component: Date,
    },
    calendar: {
      classification: 'calendar',
      component: Datepicker,
      slotProps: {
        component: ['now'],
      },
    },
    toggle: {
      classification: 'checkbox',
      component: Toggle,
    },
    species: {
      classification: 'select',
      component: Species,
    },
    client: {
      classification: 'select',
      component: Client,
    },
    locationList: {
      classification: 'none',
      component: LocationList,
    },
    tiptap: {
      classification: 'text',
      component: Tiptap,
    },
    percentage: {
      classification: 'none',
      component: Percentage,
    },
    rangeslider: {
      classification: 'none',
      component: RangeSlider,
    },
    search: {
      classification: 'text',
      component: Search,
    },
  },
  plugins: [nl],
  classes: {
    input(context) {
      switch (context.classification) {
        case 'button':
          return 'btn btn-secondary';
        case 'box':
          return 'text-primary focus:ring-primary active:ring-primary';
        case 'group':
          return '';
        case 'file':
          return 'appearance-none opacity-0 cursor-pointer absolute inset-0 w-full h-full';
        case 'none':
          return '';
        default:
          return 'font-thin block w-full py-2.5 px-3 text-gray-100 rounded-md border border-gray-600 focus:text-gray-100 focus:outline-none focus:border-gray-300';
      }
    },
    element({classification}) {
      switch (classification) {
        case 'box':
          return 'flex items-center px-1';
        default:
          return 'w-full relative';
      }
    },
    wrapper(context) {
      switch (context.classification) {
        case 'box':
          return 'w-full flex justify-start';
        default:
          return '';
      }
    },
    label(context) {
      switch (context.classification) {
        case 'box':
          return 'font-body flex-grow ml-2 text-left truncate checkbox-label';
        default:
          return 'block mt-2 mb-1 text-sm font-bold text-gray-300';
      }
    },
    help: 'text-xs mb-1 text-gray-dark',
    error: 'text-red text-xs',
    uploadArea: 'group relative h-40 overflow-hidden',
    file: ' appearance-none p-3 block flex relative items-center justify-center',
    files: 'hidden',
    uploadAreaMask({attrs: {loading}}) {
      const base = 'absolute opacity-0 pointer-events-none flex justify-center items-center inset-0 rounded upload-placeholder';

      if (loading) {
        return `${base} loading`;
      } else {
        return base;
      }
    },
  },
  errorHandler,
});
