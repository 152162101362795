import { render, staticRenderFns } from "./Species.vue?vue&type=template&id=55b49647&"
import script from "./Species.vue?vue&type=script&lang=js&"
export * from "./Species.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CircularButton: function () { return import('/data/www/partners.heem.nl/heem/nuxt/components/circularButton.vue' /* webpackChunkName: "components/circular-button" */).then(function(m) { return m['default'] || m }) }})
