import {BaseScheme} from '@nuxtjs/auth-next/dist/runtime';
import Token from '~/plugins/token';

import meQuery from '~/graphql/queries/user/me.gql';

import loginMutation from '~/graphql/mutations/auth/login.gql';
import logoutMutation from '~/graphql/mutations/auth/logout.gql';

const DEFAULTS = {
  name: 'apollo',
  token: {
    property: 'token',
    type: 'Bearer',
    name: 'Authorization',
    maxAge: 1800,
    global: true,
    required: true,
    prefix: '_token.',
    expirationPrefix: '_token_expiration.',
  },
  user: {
    property: 'user',
    autoFetch: true,
  },
};

export default class ApolloScheme extends BaseScheme {
  constructor(auth, options) {
    const opts = {...DEFAULTS, ...options};
    super(auth, opts);

    this.$auth = auth;
    this.$apollo = auth.ctx.app.apolloProvider.defaultClient;
    this.token = new Token(this, this.$auth.$storage);
    this._name = options.name;
    this._apolloCache = auth.ctx.app.apolloProvider.defaultClient.cache;
  }

  async login({
    data,
    tokenKey = 'token',
    userKey = 'user',
  }) {
    const {data: {login}} = await this.$apollo.mutate({
      mutation: loginMutation,
      variables: data,
    });
    await this.$auth.setUserToken(login);
    await this.fetchUser();
  }

  fetchUser() {
    if (!this.$auth.check().valid) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject(false);
    }

    return this.$apollo.query({query: meQuery})
      .then(({data: {me}}) => me).then(data => this.$auth.setUser(data));
  }

  async logout() {
    await this.reset();
  }

  async reset() {
    await this.$apollo.mutate({
      mutation: logoutMutation,
    });

    await this._apolloCache.reset();
    await this.$auth.setUserToken(null, null);

    return this.$auth.setUser(null);
  }
}
