import { render, staticRenderFns } from "./Button.vue?vue&type=template&id=7db4e537&"
import script from "./Button.vue?vue&type=script&lang=js&"
export * from "./Button.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Loader: function () { return import('/data/www/partners.heem.nl/heem/nuxt/components/base/Loader.vue' /* webpackChunkName: "components/loader" */).then(function(m) { return m['default'] || m }) }})
