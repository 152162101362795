import { wrapFunctional } from './utils'

export const Loader = import('../../components/base/Loader.vue' /* webpackChunkName: "components/loader" */).then(c => wrapFunctional(c.default || c))
export const Modal = import('../../components/base/Modal.vue' /* webpackChunkName: "components/modal" */).then(c => wrapFunctional(c.default || c))
export const ClientsCard = import('../../components/clients/card.vue' /* webpackChunkName: "components/clients-card" */).then(c => wrapFunctional(c.default || c))
export const ClientsForm = import('../../components/clients/form.vue' /* webpackChunkName: "components/clients-form" */).then(c => wrapFunctional(c.default || c))
export const ClientsTable = import('../../components/clients/table.vue' /* webpackChunkName: "components/clients-table" */).then(c => wrapFunctional(c.default || c))
export const ReportsAddSpeciesModal = import('../../components/reports/addSpeciesModal.vue' /* webpackChunkName: "components/reports-add-species-modal" */).then(c => wrapFunctional(c.default || c))
export const ReportsAdminOverview = import('../../components/reports/adminOverview.vue' /* webpackChunkName: "components/reports-admin-overview" */).then(c => wrapFunctional(c.default || c))
export const ReportsCard = import('../../components/reports/card.vue' /* webpackChunkName: "components/reports-card" */).then(c => wrapFunctional(c.default || c))
export const ReportsContentModal = import('../../components/reports/contentModal.vue' /* webpackChunkName: "components/reports-content-modal" */).then(c => wrapFunctional(c.default || c))
export const ReportsCustomerImage = import('../../components/reports/customerImage.vue' /* webpackChunkName: "components/reports-customer-image" */).then(c => wrapFunctional(c.default || c))
export const ReportsCustomerOverview = import('../../components/reports/customerOverview.vue' /* webpackChunkName: "components/reports-customer-overview" */).then(c => wrapFunctional(c.default || c))
export const ReportsDetailsModal = import('../../components/reports/detailsModal.vue' /* webpackChunkName: "components/reports-details-modal" */).then(c => wrapFunctional(c.default || c))
export const ReportsImage = import('../../components/reports/image.vue' /* webpackChunkName: "components/reports-image" */).then(c => wrapFunctional(c.default || c))
export const ReportsImageBigModal = import('../../components/reports/imageBigModal.vue' /* webpackChunkName: "components/reports-image-big-modal" */).then(c => wrapFunctional(c.default || c))
export const ReportsImageDeleteModal = import('../../components/reports/imageDeleteModal.vue' /* webpackChunkName: "components/reports-image-delete-modal" */).then(c => wrapFunctional(c.default || c))
export const ReportsImageRenameModal = import('../../components/reports/imageRenameModal.vue' /* webpackChunkName: "components/reports-image-rename-modal" */).then(c => wrapFunctional(c.default || c))
export const ReportsLongCard = import('../../components/reports/longCard.vue' /* webpackChunkName: "components/reports-long-card" */).then(c => wrapFunctional(c.default || c))
export const ReportsNewReports = import('../../components/reports/newReports.vue' /* webpackChunkName: "components/reports-new-reports" */).then(c => wrapFunctional(c.default || c))
export const ReportsSaveButton = import('../../components/reports/saveButton.vue' /* webpackChunkName: "components/reports-save-button" */).then(c => wrapFunctional(c.default || c))
export const ReportsSendModal = import('../../components/reports/sendModal.vue' /* webpackChunkName: "components/reports-send-modal" */).then(c => wrapFunctional(c.default || c))
export const ReportsSpeciesCard = import('../../components/reports/speciesCard.vue' /* webpackChunkName: "components/reports-species-card" */).then(c => wrapFunctional(c.default || c))
export const ReportsTable = import('../../components/reports/table.vue' /* webpackChunkName: "components/reports-table" */).then(c => wrapFunctional(c.default || c))
export const ReportsChartsOccurrencesInDistrictChart1 = import('../../components/reports/charts/occurrences-in-district/chart-1.vue' /* webpackChunkName: "components/reports-charts-occurrences-in-district-chart1" */).then(c => wrapFunctional(c.default || c))
export const ReportsChartsOccurrencesInDistrictChart2 = import('../../components/reports/charts/occurrences-in-district/chart-2.vue' /* webpackChunkName: "components/reports-charts-occurrences-in-district-chart2" */).then(c => wrapFunctional(c.default || c))
export const ReportsChartsOccurrencesInDistrict = import('../../components/reports/charts/occurrences-in-district/index.vue' /* webpackChunkName: "components/reports-charts-occurrences-in-district" */).then(c => wrapFunctional(c.default || c))
export const ReportsChartsSpeciesChartsChart1a = import('../../components/reports/charts/species-charts/chart-1a.vue' /* webpackChunkName: "components/reports-charts-species-charts-chart1a" */).then(c => wrapFunctional(c.default || c))
export const ReportsChartsSpeciesChartsChart1b = import('../../components/reports/charts/species-charts/chart-1b.vue' /* webpackChunkName: "components/reports-charts-species-charts-chart1b" */).then(c => wrapFunctional(c.default || c))
export const ReportsChartsSpeciesChartsChart1c = import('../../components/reports/charts/species-charts/chart-1c.vue' /* webpackChunkName: "components/reports-charts-species-charts-chart1c" */).then(c => wrapFunctional(c.default || c))
export const ReportsChartsSpeciesCharts = import('../../components/reports/charts/species-charts/index.vue' /* webpackChunkName: "components/reports-charts-species-charts" */).then(c => wrapFunctional(c.default || c))
export const ReportsChartsStructureChart1 = import('../../components/reports/charts/structure/chart-1.vue' /* webpackChunkName: "components/reports-charts-structure-chart1" */).then(c => wrapFunctional(c.default || c))
export const ReportsChartsStructureChart2 = import('../../components/reports/charts/structure/chart-2.vue' /* webpackChunkName: "components/reports-charts-structure-chart2" */).then(c => wrapFunctional(c.default || c))
export const ReportsChartsStructure = import('../../components/reports/charts/structure/index.vue' /* webpackChunkName: "components/reports-charts-structure" */).then(c => wrapFunctional(c.default || c))
export const ReportsChartsValues = import('../../components/reports/charts/values/index.vue' /* webpackChunkName: "components/reports-charts-values" */).then(c => wrapFunctional(c.default || c))
export const ReportsChartsValuesAcidityChart1 = import('../../components/reports/charts/values/acidity/chart-1.vue' /* webpackChunkName: "components/reports-charts-values-acidity-chart1" */).then(c => wrapFunctional(c.default || c))
export const ReportsChartsValuesAcidityChart2 = import('../../components/reports/charts/values/acidity/chart-2.vue' /* webpackChunkName: "components/reports-charts-values-acidity-chart2" */).then(c => wrapFunctional(c.default || c))
export const ReportsChartsValuesMoistureChart1 = import('../../components/reports/charts/values/moisture/chart-1.vue' /* webpackChunkName: "components/reports-charts-values-moisture-chart1" */).then(c => wrapFunctional(c.default || c))
export const ReportsChartsValuesMoistureChart2 = import('../../components/reports/charts/values/moisture/chart-2.vue' /* webpackChunkName: "components/reports-charts-values-moisture-chart2" */).then(c => wrapFunctional(c.default || c))
export const ReportsChartsValuesSalinityChart1 = import('../../components/reports/charts/values/salinity/chart-1.vue' /* webpackChunkName: "components/reports-charts-values-salinity-chart1" */).then(c => wrapFunctional(c.default || c))
export const ReportsChartsValuesSalinityChart2 = import('../../components/reports/charts/values/salinity/chart-2.vue' /* webpackChunkName: "components/reports-charts-values-salinity-chart2" */).then(c => wrapFunctional(c.default || c))
export const ProjectsCard = import('../../components/projects/card.vue' /* webpackChunkName: "components/projects-card" */).then(c => wrapFunctional(c.default || c))
export const ProjectsEditForm = import('../../components/projects/editForm.vue' /* webpackChunkName: "components/projects-edit-form" */).then(c => wrapFunctional(c.default || c))
export const ProjectsMainForm = import('../../components/projects/mainForm.vue' /* webpackChunkName: "components/projects-main-form" */).then(c => wrapFunctional(c.default || c))
export const ProjectsNewForm = import('../../components/projects/newForm.vue' /* webpackChunkName: "components/projects-new-form" */).then(c => wrapFunctional(c.default || c))
export const ProjectsRecentProjects = import('../../components/projects/recentProjects.vue' /* webpackChunkName: "components/projects-recent-projects" */).then(c => wrapFunctional(c.default || c))
export const ProjectsTable = import('../../components/projects/table.vue' /* webpackChunkName: "components/projects-table" */).then(c => wrapFunctional(c.default || c))
export const LocationsLocationTab = import('../../components/locations/locationTab.vue' /* webpackChunkName: "components/locations-location-tab" */).then(c => wrapFunctional(c.default || c))
export const LocationsOverview = import('../../components/locations/overview.vue' /* webpackChunkName: "components/locations-overview" */).then(c => wrapFunctional(c.default || c))
export const SpeciesBlock = import('../../components/species/SpeciesBlock.vue' /* webpackChunkName: "components/species-block" */).then(c => wrapFunctional(c.default || c))
export const SpeciesCompactCard = import('../../components/species/compactCard.vue' /* webpackChunkName: "components/species-compact-card" */).then(c => wrapFunctional(c.default || c))
export const SpeciesModal = import('../../components/species/modal.vue' /* webpackChunkName: "components/species-modal" */).then(c => wrapFunctional(c.default || c))
export const UsersTable = import('../../components/users/table.vue' /* webpackChunkName: "components/users-table" */).then(c => wrapFunctional(c.default || c))
export const ChartsBar = import('../../components/charts/bar.vue' /* webpackChunkName: "components/charts-bar" */).then(c => wrapFunctional(c.default || c))
export const ChartsLine = import('../../components/charts/line.vue' /* webpackChunkName: "components/charts-line" */).then(c => wrapFunctional(c.default || c))
export const CircularButton = import('../../components/circularButton.vue' /* webpackChunkName: "components/circular-button" */).then(c => wrapFunctional(c.default || c))
export const CircularToggle = import('../../components/circularToggle.vue' /* webpackChunkName: "components/circular-toggle" */).then(c => wrapFunctional(c.default || c))
export const Paginator = import('../../components/paginator.vue' /* webpackChunkName: "components/paginator" */).then(c => wrapFunctional(c.default || c))
export const Return = import('../../components/return.vue' /* webpackChunkName: "components/return" */).then(c => wrapFunctional(c.default || c))
export const User = import('../../components/user.vue' /* webpackChunkName: "components/user" */).then(c => wrapFunctional(c.default || c))
export const FormulateButton = import('../../components/formulate/Button.vue' /* webpackChunkName: "components/formulate-button" */).then(c => wrapFunctional(c.default || c))
export const FormulateCalendar = import('../../components/formulate/Calendar.vue' /* webpackChunkName: "components/formulate-calendar" */).then(c => wrapFunctional(c.default || c))
export const FormulateClient = import('../../components/formulate/Client.vue' /* webpackChunkName: "components/formulate-client" */).then(c => wrapFunctional(c.default || c))
export const FormulateDate = import('../../components/formulate/Date.vue' /* webpackChunkName: "components/formulate-date" */).then(c => wrapFunctional(c.default || c))
export const FormulateLocationList = import('../../components/formulate/LocationList.vue' /* webpackChunkName: "components/formulate-location-list" */).then(c => wrapFunctional(c.default || c))
export const FormulatePercentage = import('../../components/formulate/Percentage.vue' /* webpackChunkName: "components/formulate-percentage" */).then(c => wrapFunctional(c.default || c))
export const FormulateRangeSlider = import('../../components/formulate/RangeSlider.vue' /* webpackChunkName: "components/formulate-range-slider" */).then(c => wrapFunctional(c.default || c))
export const FormulateSearch = import('../../components/formulate/Search.vue' /* webpackChunkName: "components/formulate-search" */).then(c => wrapFunctional(c.default || c))
export const FormulateSpecies = import('../../components/formulate/Species.vue' /* webpackChunkName: "components/formulate-species" */).then(c => wrapFunctional(c.default || c))
export const FormulateTiptap = import('../../components/formulate/Tiptap.vue' /* webpackChunkName: "components/formulate-tiptap" */).then(c => wrapFunctional(c.default || c))
export const FormulateToggle = import('../../components/formulate/Toggle.vue' /* webpackChunkName: "components/formulate-toggle" */).then(c => wrapFunctional(c.default || c))
