import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _1ccb3276 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _16647bd0 = () => interopDefault(import('../pages/form.vue' /* webpackChunkName: "pages/form" */))
const _a424cfa8 = () => interopDefault(import('../pages/gebruikers/index.vue' /* webpackChunkName: "pages/gebruikers/index" */))
const _0471947a = () => interopDefault(import('../pages/opdrachtgevers/index.vue' /* webpackChunkName: "pages/opdrachtgevers/index" */))
const _5d49d329 = () => interopDefault(import('../pages/projecten/index.vue' /* webpackChunkName: "pages/projecten/index" */))
const _83876fc6 = () => interopDefault(import('../pages/rapportages/index.vue' /* webpackChunkName: "pages/rapportages/index" */))
const _38772e30 = () => interopDefault(import('../pages/speciesImport/index.vue' /* webpackChunkName: "pages/speciesImport/index" */))
const _537ca3c8 = () => interopDefault(import('../pages/auth/inloggen.vue' /* webpackChunkName: "pages/auth/inloggen" */))
const _6446de0d = () => interopDefault(import('../pages/auth/wachtwoord-vergeten/index.vue' /* webpackChunkName: "pages/auth/wachtwoord-vergeten/index" */))
const _32878cd4 = () => interopDefault(import('../pages/opdrachtgevers/nieuw.vue' /* webpackChunkName: "pages/opdrachtgevers/nieuw" */))
const _e94068fa = () => interopDefault(import('../pages/projecten/nieuw.vue' /* webpackChunkName: "pages/projecten/nieuw" */))
const _9a2c01c2 = () => interopDefault(import('../pages/auth/activeren/_token.vue' /* webpackChunkName: "pages/auth/activeren/_token" */))
const _ab470962 = () => interopDefault(import('../pages/auth/wachtwoord-vergeten/_token.vue' /* webpackChunkName: "pages/auth/wachtwoord-vergeten/_token" */))
const _e4dc7d76 = () => interopDefault(import('../pages/opdrachtgevers/_uuid/index.vue' /* webpackChunkName: "pages/opdrachtgevers/_uuid/index" */))
const _5fb3fe98 = () => interopDefault(import('../pages/projecten/_uuid/index.vue' /* webpackChunkName: "pages/projecten/_uuid/index" */))
const _1c3c22b0 = () => interopDefault(import('../pages/rapportages/_uuid/index.vue' /* webpackChunkName: "pages/rapportages/_uuid/index" */))
const _525066d0 = () => interopDefault(import('../pages/opdrachtgevers/_uuid/bewerken.vue' /* webpackChunkName: "pages/opdrachtgevers/_uuid/bewerken" */))
const _7e916183 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/en",
    component: _1ccb3276,
    pathToRegexpOptions: {"strict":true},
    name: "index___en"
  }, {
    path: "/form",
    component: _16647bd0,
    pathToRegexpOptions: {"strict":true},
    name: "form___nl"
  }, {
    path: "/gebruikers",
    component: _a424cfa8,
    pathToRegexpOptions: {"strict":true},
    name: "gebruikers___nl"
  }, {
    path: "/opdrachtgevers",
    component: _0471947a,
    pathToRegexpOptions: {"strict":true},
    name: "opdrachtgevers___nl"
  }, {
    path: "/projecten",
    component: _5d49d329,
    pathToRegexpOptions: {"strict":true},
    name: "projecten___nl"
  }, {
    path: "/rapportages",
    component: _83876fc6,
    pathToRegexpOptions: {"strict":true},
    name: "rapportages___nl"
  }, {
    path: "/speciesImport",
    component: _38772e30,
    pathToRegexpOptions: {"strict":true},
    name: "speciesImport___nl"
  }, {
    path: "/auth/inloggen",
    component: _537ca3c8,
    pathToRegexpOptions: {"strict":true},
    name: "auth-inloggen___nl"
  }, {
    path: "/auth/wachtwoord-vergeten",
    component: _6446de0d,
    pathToRegexpOptions: {"strict":true},
    name: "auth-wachtwoord-vergeten___nl"
  }, {
    path: "/en/form",
    component: _16647bd0,
    pathToRegexpOptions: {"strict":true},
    name: "form___en"
  }, {
    path: "/en/gebruikers",
    component: _a424cfa8,
    pathToRegexpOptions: {"strict":true},
    name: "gebruikers___en"
  }, {
    path: "/en/opdrachtgevers",
    component: _0471947a,
    pathToRegexpOptions: {"strict":true},
    name: "opdrachtgevers___en"
  }, {
    path: "/en/projecten",
    component: _5d49d329,
    pathToRegexpOptions: {"strict":true},
    name: "projecten___en"
  }, {
    path: "/en/rapportages",
    component: _83876fc6,
    pathToRegexpOptions: {"strict":true},
    name: "rapportages___en"
  }, {
    path: "/en/speciesImport",
    component: _38772e30,
    pathToRegexpOptions: {"strict":true},
    name: "speciesImport___en"
  }, {
    path: "/opdrachtgevers/nieuw",
    component: _32878cd4,
    pathToRegexpOptions: {"strict":true},
    name: "opdrachtgevers-nieuw___nl"
  }, {
    path: "/projecten/nieuw",
    component: _e94068fa,
    pathToRegexpOptions: {"strict":true},
    name: "projecten-nieuw___nl"
  }, {
    path: "/en/auth/inloggen",
    component: _537ca3c8,
    pathToRegexpOptions: {"strict":true},
    name: "auth-inloggen___en"
  }, {
    path: "/en/auth/wachtwoord-vergeten",
    component: _6446de0d,
    pathToRegexpOptions: {"strict":true},
    name: "auth-wachtwoord-vergeten___en"
  }, {
    path: "/en/opdrachtgevers/nieuw",
    component: _32878cd4,
    pathToRegexpOptions: {"strict":true},
    name: "opdrachtgevers-nieuw___en"
  }, {
    path: "/en/projecten/nieuw",
    component: _e94068fa,
    pathToRegexpOptions: {"strict":true},
    name: "projecten-nieuw___en"
  }, {
    path: "/en/auth/activeren/:token?",
    component: _9a2c01c2,
    pathToRegexpOptions: {"strict":true},
    name: "auth-activeren-token___en"
  }, {
    path: "/en/auth/wachtwoord-vergeten/:token?",
    component: _ab470962,
    pathToRegexpOptions: {"strict":true},
    name: "auth-wachtwoord-vergeten-token___en"
  }, {
    path: "/auth/activeren/:token?",
    component: _9a2c01c2,
    pathToRegexpOptions: {"strict":true},
    name: "auth-activeren-token___nl"
  }, {
    path: "/auth/wachtwoord-vergeten/:token?",
    component: _ab470962,
    pathToRegexpOptions: {"strict":true},
    name: "auth-wachtwoord-vergeten-token___nl"
  }, {
    path: "/en/opdrachtgevers/:uuid",
    component: _e4dc7d76,
    pathToRegexpOptions: {"strict":true},
    name: "opdrachtgevers-uuid___en"
  }, {
    path: "/en/projecten/:uuid",
    component: _5fb3fe98,
    pathToRegexpOptions: {"strict":true},
    name: "projecten-uuid___en"
  }, {
    path: "/en/rapportages/:uuid",
    component: _1c3c22b0,
    pathToRegexpOptions: {"strict":true},
    name: "rapportages-uuid___en"
  }, {
    path: "/en/opdrachtgevers/:uuid/bewerken",
    component: _525066d0,
    pathToRegexpOptions: {"strict":true},
    name: "opdrachtgevers-uuid-bewerken___en"
  }, {
    path: "/opdrachtgevers/:uuid",
    component: _e4dc7d76,
    pathToRegexpOptions: {"strict":true},
    name: "opdrachtgevers-uuid___nl"
  }, {
    path: "/projecten/:uuid",
    component: _5fb3fe98,
    pathToRegexpOptions: {"strict":true},
    name: "projecten-uuid___nl"
  }, {
    path: "/rapportages/:uuid",
    component: _1c3c22b0,
    pathToRegexpOptions: {"strict":true},
    name: "rapportages-uuid___nl"
  }, {
    path: "/opdrachtgevers/:uuid/bewerken",
    component: _525066d0,
    pathToRegexpOptions: {"strict":true},
    name: "opdrachtgevers-uuid-bewerken___nl"
  }, {
    path: "/en/*",
    component: _7e916183,
    pathToRegexpOptions: {"strict":true},
    name: "all___en"
  }, {
    path: "/",
    component: _1ccb3276,
    pathToRegexpOptions: {"strict":true},
    name: "index___nl"
  }, {
    path: "/*",
    component: _7e916183,
    pathToRegexpOptions: {"strict":true},
    name: "all___nl"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
