//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    value: [1, 10],
    options: {
      process: pos => [
        [pos[0], pos[1]],
      ],
      enableCross: true,
      min: 1,
      max: 10,
    },
  }),
};
