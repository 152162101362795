//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapState} from 'vuex';

export default {
  layout: ({$auth, app}) => {
    const statusCode = app.nuxt.err?.statusCode || null;
    return $auth?.loggedIn && statusCode !== 503 ? 'default' : 'auth';
  },
  props: {
    error: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState(['page']),
  },
  async created() {
    if (this.error.statusCode === 410 || this.error.statusCode === 404) {
      await this.$store.dispatch('getPage', '404');
    }
  },
  head() {
    return {
      title: this.error.statusCode === 503 ? 'Onderhoudsmodus' : this.page.seo ? this.page.seo.meta_title : this.page?.title,
    };
  },
};

